import React, { useEffect, useRef } from "react";

import SignaturePad from "signature_pad";

import BSModal, { BSModalDefaultProps } from "../core/BSModal";

type SignModelProps = BSModalDefaultProps & {
  onSignatureBase64: (base64: string) => void;
};

const SignModel = ({
  isVisible,
  onClose,
  onSignatureBase64,
  ...props
}: SignModelProps) => {
  const canvasRef = useRef(null);
  const sigPadRef = useRef(null);

  const handleClear = () => {
    sigPadRef.current.clear();
  };

  const handleSave = () => {
    const dataURL = sigPadRef.current.toDataURL();
    onSignatureBase64(dataURL);
  };

  function resizeCanvas() {
    if (!canvasRef.current) return;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvasRef.current.width = canvasRef.current.offsetWidth * ratio;
    canvasRef.current.height = canvasRef.current.offsetHeight * ratio;
    canvasRef.current?.getContext("2d").scale(ratio, ratio);
    handleClear(); // otherwise isEmpty() might return incorrect value
  }

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const sigPad = new SignaturePad(canvas, {
        backgroundColor: "rgba(255, 255, 255, 1)",
        penColor: "rgb(0, 0, 0)",
      });
      sigPadRef.current = sigPad;
    }
  }, [canvasRef.current, isVisible]);

  useEffect(() => {
    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();
  }, [canvasRef.current, isVisible]);

  return (
    <BSModal
      size="lg"
      isVisible={isVisible}
      onClose={onClose}
      modalProps={{
        centered: true,
      }}
      hideHeader
      modalBodyClassName="px-0 pt-0"
    >
      <div className="px-3 px-md-5 py-3">
        <h1 className="h1-text font-color-black text-center border-bottom pb-3 work-sans">
          Sign SWMS
        </h1>

        <div className="text-center">
          <span className="body-text lh-2">
            Sign the SWMS in the space below using your mouse or a smart pen:
          </span>
        </div>

        <div className="text-center my-4" style={{ height: 350 }}>
          <canvas
            ref={canvasRef}
            className="border border-dark signature-pad w-100 h-100"
          ></canvas>
        </div>

        <div className="row justify-content-between">
          <div className="col-12 col-md-4">
            <button
              title="close"
              aria-label="Close Modal"
              type="button"
              className="btn primary-btn-black fw-bold h-100 w-100"
              onClick={() => {
                onClose();
                handleClear();
              }}
            >
              Close
            </button>
          </div>
          <div className="col-12 col-md-4">
            <button
              title="reset"
              aria-label="Reset Signature Pad"
              type="button"
              className="btn primary-btn-black fw-bold h-100 w-100"
              onClick={handleClear}
            >
              Reset Sign
            </button>
          </div>
          <div className="col-12 col-md-4 mt-3 mt-md-0">
            <button
              title="confirm sign"
              aria-label="Confirm SWMS Sign"
              type="button"
              className="btn primary-btn fw-bold h-100 w-100"
              onClick={handleSave}
            >
              SIGN SWMS
            </button>
          </div>
        </div>
      </div>
    </BSModal>
  );
};

export default SignModel;
