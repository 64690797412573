import BSModal, {
  BSModalDefaultProps,
} from "../../../../components/core/BSModal";
import moment from "moment";
import React, { useState } from "react";
import Slider from "react-slick";
import { FileDto } from "../../../../services/users";
import { openInNewTab } from "../../../../utils/CommonUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
declare type ViewPhotoModalProps = BSModalDefaultProps & {
  imageList: FileDto[];
  title?: string;
};

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      title="view next"
      aria-label="Navigate to Next Image"
      className="end-0 pe-3 position-absolute top-50 bg-transparent border-0 translate-middle-y"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronRight} className="image-arrow fa-sharp" />
    </button>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      title="view previous"
      aria-label="Navigate to Previous Image"
      onClick={onClick}
      className="position-absolute ps-3 start-0 top-50 bg-transparent border-0 translate-middle-y"
      style={{ zIndex: 1 }}
    >
      <FontAwesomeIcon icon={faChevronLeft} className="image-arrow fa-sharp" />
    </button>
  );
}

const ViewPhotoModal = ({
  isVisible,
  imageList,
  title,
  onClose,
  ...props
}: ViewPhotoModalProps) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: (indexOfCurrentSlide) => {
      isVisible && setCurrentSlide(indexOfCurrentSlide);
    },
  };

  return (
    <BSModal
      size="lg"
      hideHeader
      isVisible={isVisible}
      onClose={() => {
        setCurrentSlide(0);
        onClose();
      }}
      modalBodyClassName="px-0 pt-0"
      modalProps={{
        centered: true,
      }}
    >
      <div className="px-3 pt-3 job-manage ">
        <div className="mx-4 my-3 pre-start-safety-check">
          <div className=" position-relative row">
            <div>
              <h2 className="h2-text font-color-black text-center border-bottom pb-3 work-sans">
                {title ?? "Photo of Hazards On Site"}
              </h2>
            </div>
            <div className="d-flex justify-content-end position-absolute">
              <button
                className="bg-transparent border-0"
                title="close"
                aria-label="Close Modal"
                onClick={() => {
                  setCurrentSlide(0);
                  onClose();
                }}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  className="modal-close-icon fa-sharp"
                />
              </button>
            </div>
          </div>
          {imageList?.length > 0 && (
            <Slider {...settings}>
              {imageList.map((e, index) => {
                return (
                  <div key={`image=${index}`} className="aspect-image">
                    <img
                      src={e.inlineUrl}
                      alt={title ?? "Photo of Hazards On Site"}
                    />
                  </div>
                );
              })}
            </Slider>
          )}
          {imageList?.length > 0 && (
            <div className="black-bg d-flex flex-column-reverse flex-md-row font-color-white justify-content-md-between p-2">
              <span className="d-block text-center">
                Date Submitted:{" "}
                {moment(imageList[currentSlide]?.created_on).format(
                  "DD/MM/YYYY - hh:mm A"
                )}
              </span>
              <span className="d-block text-center">
                {currentSlide + 1} of {imageList?.length}
              </span>
            </div>
          )}
          <div className="col-12 d-flex flex-column flex-md-row justify-content-between mt-4">
            <div className="col-12 col-lg-3 col-md-4">
              <button
                title="close"
                aria-label="Close Modal"
                type="button"
                className="btn primary-btn-black w-100"
                onClick={onClose}
              >
                Close
              </button>
            </div>
            <div className="col-12 col-lg-3 col-md-4 mt-2 mt-md-0">
              <button
                title="download"
                aria-label="Download Image"
                type="button"
                className="btn primary-btn w-100"
                onClick={() => {
                  openInNewTab(imageList[currentSlide].url!);
                }}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </BSModal>
  );
};

export default ViewPhotoModal;
