import React, {
  ForwardRefExoticComponent,
  Fragment,
  RefAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import { Form, FormControlProps, InputGroup, Spinner } from "react-bootstrap";
import CommonIcons from "utils/CommonIcons";

interface AutocompleteProps {
  cansubmitCustom?: boolean;
  title?: string;
  controlId: string;
  isDescription?: boolean;
  isSOWOpen?: boolean;
  isPCROpen?: boolean;
  data: any[];
  value?: string;
  error?: string;
  isOpen?: boolean;
  suggestionClassName?: string;
  expandable?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  asterisk?: boolean;
  loadSuggestions?: boolean;
  onHideSuggestion?: boolean;
  renderSuggestionItem: (item: any, index: number) => JSX.Element;
  clearSuggestions?: () => void;
  onSuggestionClick?: (item: any, index?: number) => void;
  onChange?: FormControlProps["onChange"];
}

const Autocomplete = ({
  controlId,
  isDescription,
  title,
  data,
  value,
  error,
  isSOWOpen,
  isPCROpen,
  isOpen,
  suggestionClassName,
  cansubmitCustom,
  expandable,
  disabled,
  asterisk,
  isLoading,
  loadSuggestions,
  clearSuggestions,
  onHideSuggestion,
  onSuggestionClick,
  renderSuggestionItem,
  onChange,
}: AutocompleteProps) => {
  const [currIndex, setCurrIndex] = useState(-1);

  const typeaheadRef = useRef<any>();

  useEffect(() => {
    if (currIndex != -1) {
      window?.scrollToViewAutocomplete(`#${controlId}-${currIndex + 1}`);
    }
  }, [currIndex]);

  const onItemClick = (item: any, index?: number) => {
    if (typeaheadRef?.current) typeaheadRef.current.blur();

    if (onSuggestionClick) onSuggestionClick(item, index);
  };

  const noResult =
    !isLoading && data.length <= 0 && value?.trim() != "" && loadSuggestions;

  const renderSuggestions = () => {
    if (isLoading) {
      return (
        <div
          role="option"
          className="border-bottom px-3 py-2 bg-white"
          tabIndex={-1}
        >
          <div className="d-flex align-items-center">
            <strong>Loading...</strong>
            <div
              className="spinner-border ms-auto spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></div>
          </div>
        </div>
      );
    }
    if (noResult) {
      if (cansubmitCustom) return;
      return (
        <div
          role="option"
          className="border-bottom px-3 py-2 bg-white fw-bold"
          tabIndex={-1}
        >
          <span>
            {cansubmitCustom ? "Create New Trade" : "No results found."}
          </span>
        </div>
      );
    }
    return data.map((suggestion, index) => {
      return (
        <div
          className={`item-option ${currIndex == index ? "active" : ""}`}
          key={`${controlId}-${index + 1}`}
          onClick={() => {
            onItemClick(suggestion, index);
          }}
          id={`${controlId}-${index + 1}`}
          role="option"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key == " " || e.key == "Enter") {
              // onItemClick(suggestion, index);
              e.currentTarget.click();
              e.preventDefault();
            }
          }}
        >
          {renderSuggestionItem(suggestion, index)}
        </div>
      );
    });
  };

  return (
    <Form.Group className="col-12 mb-4 position-relative" controlId={controlId}>
      {title && (
        <>
          <Form.Label className="fw-bold text-capitalize">
            {title}
            <span className="font-color-red">{asterisk ? "* " : ""}</span>
          </Form.Label>
          {isDescription && (
            <p>
              Address will auto populate or select arrow at end of entry field
              to enter all details manually.
            </p>
          )}
        </>
      )}
      <InputGroup>
        <Form.Control
          type="text"
          className={`${!expandable ? "no-toggle" : ""}`}
          value={value ?? ""}
          onChange={onChange}
          disabled={disabled}
          isInvalid={error != undefined}
          onBlur={() => {
            setTimeout(() => {
              setCurrIndex(-1);
              if (clearSuggestions) clearSuggestions();
            }, 300);
          }}
          placeholder={
            isOpen || isSOWOpen || isPCROpen ? "Search for existing" : ""
          }
          autoComplete="off"
          onKeyDown={(e) => {
            // handle up down arrow events
            if (e.key == "Escape") {
              setCurrIndex(-1);
              if (clearSuggestions) clearSuggestions();
            }
            if (e.key == "Enter") {
              if (currIndex != -1) {
                e.preventDefault();
                onItemClick(data[currIndex], currIndex);
              }
            }
            if (e.key == "ArrowDown") {
              e.preventDefault();
              if (currIndex + 1 < data.length) {
                setCurrIndex(currIndex + 1);
              } else {
                if (data.length != 0) setCurrIndex(0);
              }
            }

            if (e.key == "ArrowUp") {
              e.preventDefault();
              if (currIndex - 1 > -1) {
                setCurrIndex(currIndex - 1);
              } else {
                if (data.length != 0) setCurrIndex(data.length - 1);
              }
            }
          }}
        />
        {expandable && (
          <InputGroup.Text
            id="autocomplete-toggle"
            tabIndex={0}
            className="border-start-0"
          >
            {isOpen || isSOWOpen || isPCROpen ? (
              <CommonIcons.ArrowDown className="autocomplete-btn" />
            ) : (
              <CommonIcons.ArrowRight className="autocomplete-btn" />
            )}
          </InputGroup.Text>
        )}
        <Form.Control.Feedback
          className="font-color-red poppins"
          type="invalid"
        >
          {error}
        </Form.Control.Feedback>
      </InputGroup>
      <div
        className={`autocomplete-items text-start ${
          (suggestionClassName ?? "") +
          (onHideSuggestion ? "d-none " : "") +
          (error == undefined && isLoading && noResult && data.length
            ? "border-bottom"
            : "")
        }`}
        id="contact-autocomplete"
      >
        {renderSuggestions()}
      </div>
    </Form.Group>
  );
};

export default Autocomplete;
