import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTitle } from "utils/UseTitleUtils";
import CenterLoading from "components/core/CenterLoading";
import FileSelect from "components/core/FileSelect";
import users, { FileDto } from "services/users";

const importListData = [
  {
    value: "all",
    name: "Select All",
  },
  {
    value: "client",
    name: "Clients",
  },
  {
    value: "worksite",
    name: "Worksites",
  },
  {
    value: "project",
    name: "Projects",
  },
  {
    value: "job",
    name: "Jobs",
  },
];

declare type InputErrors = {
  fileError?: string;
  importType?: string;
};

const ImportData = () => {
  useTitle("Import Data");
  const [isLoading, setIsLoading] = useState(false);
  const [importType, setImportType] = useState([]);
  const [fileData, setFileData] = useState<File>();
  const [errors, setErrors] = useState<InputErrors>({});

  const submitDetails = async () => {
    setIsLoading(true);
    let tempErrors: InputErrors = {};
    if (importType.length === 0) {
      tempErrors.importType = "Please select atleast one type to import";
    }
    try {
      const req = {
        type: importType.join(", "),
      };

      const sowFileId = await uploadDoc();
      if (sowFileId) {
        req["fileId"] = sowFileId;
        const response = await users.importData(req);
        console.log("response", response);
      } else {
        tempErrors.fileError = "Please upload data";
      }
      setErrors(tempErrors);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  function errorMessage(field: string) {
    if (!errors) return null;
    if (typeof errors[field] === "undefined") return null;
    return (
      <Form.Control.Feedback
        className="d-block font-color-red poppins"
        type="invalid"
      >
        {errors[field]}
      </Form.Control.Feedback>
    );
  }

  const uploadDoc = async () => {
    if (!fileData) {
      return undefined;
    }
    setIsLoading(true);

    const formData = new FormData();
    formData.append("fileType", "import_file");
    formData.append("file", fileData);

    try {
      const response: FileDto = await users.uploadFile(formData);

      return response.id;
      // createProject(response.id);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      return undefined;
    }
  };

  return (
    <div className="content-section poppins p-3 p-md-5 d-flex flex-fill flex-column">
      <CenterLoading isVisible={isLoading} />

      <h1 className="h1-text font-color-black poppins">Import data</h1>
      <span className="body-text lh-2">
        Use this page to import your data into the system. You can select which
        types of data to import and upload your Excel spreadsheet.
      </span>

      <div className="row mt-4">
        <Form.Group controlId="dateParams" className="col-12 mb-2">
          <Form.Label>Select Data Types to Import:</Form.Label>
          {importListData.map((x, index) => {
            return (
              <Form.Check
                key={index}
                className="d-block mb-2"
                label={x.name}
                aria-label={x.name}
                onChange={(e) => {
                  if (e.target.checked) {
                    setImportType([...importType, x.value]);
                    setErrors({ ...errors, importType: undefined });
                  } else {
                    setImportType(importType.filter((a) => a !== x.value));
                  }
                }}
                id={x.name}
              />
            );
          })}
          {errors["importType"] && errorMessage("importType")}
        </Form.Group>
        <Form.Group controlId="fileData" className="col-12">
          <Form.Label>Upload Data:</Form.Label>
          <FileSelect
            accept=".zip,.doc,.docx,.txt,.pdf"
            buttonName="Upload Data"
            name="fileData"
            formGroupClassName="mt-1"
            controlId="fileData"
            error={errors["fileError"]}
            onSelectFile={(file) => {
              setFileData(file);
            }}
          />
          {/* {errors["fileError"] && errorMessage("fileError")} */}
        </Form.Group>
      </div>

      <div className="row g-3 mt-3">
        <div className="col-12 col-md-6">
          <button
            title="create worksite"
            aria-label="Create New Worksite"
            className="btn primary-btn w-100"
            onClick={submitDetails}
          >
            Upload Data
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImportData;
