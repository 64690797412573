import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";

import { Contact } from "../../services/users";

import Address, { AddressData } from "../address";

import EmergencyContact from "./EmergencyContact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { isEqual } from "lodash";

declare type InputType =
  | "fullName"
  | "firstName"
  | "familyName"
  | "lastName"
  | "email"
  | "password"
  | "newPassword"
  | "currentPassword"
  | "confirmPassword"
  | "companyName"
  | "emailAddressWork"
  | "emailAddressPersonal"
  | "organizationName"
  | "organizationTradingName"
  | "organizationAbn"
  | "organizationAcn"
  | "organizationEmail"
  | "phoneNumber"
  | "officePhoneMain"
  | "officePhoneDirect"
  | "address"
  | "emergencyContact";

// These are the types which will set input as password otherwise it will be text
const possiblePasswordTypes: string[] = ["password", "confirmPassword"];

export declare type ContactInput = {
  title: string;
  defaultValue?: any;
  type: InputType | string;
  required?: boolean;
  isName?: boolean;
  error: JSX.Element | string | undefined;
  disabled?: boolean;
  value?: string;
};

export declare type ContactFormData = Contact & {
  lastName?: string;
  address?: AddressData;
  emergencyContact?: Contact;
  password?: string;
  confirmPassword?: string;
  phoneNumber?: string;
  leadingHand?: boolean;
};

interface ContactFormProps {
  inputList: ContactInput[];
  onContactUpdate: (data: ContactFormData) => void;
  defaultFromApi?: boolean;
  isLoading?: boolean;
  asterisk?: boolean;
}

/**
 * This function will be used to get autocomplete type based on input field you pass
 *
 * @param type input type
 * @returns autocomplete type
 */
export const getAutocompleteType = (type: InputType) => {
  switch (type) {
    case "fullName":
      return "name";

    case "firstName":
      return "given-name";

    case "familyName":
    case "lastName":
      return "family-name";

    case "companyName":
    case "organizationName":

    case "organizationTradingName":
      return "organization";

    case "organizationEmail":
    case "emailAddressWork":
    case "emailAddressPersonal":
    case "email":
      return "email";

    case "password":
    case "newPassword":
    case "confirmPassword":
      return "new-password";

    case "currentPassword":
      return "current-password";

    case "phoneNumber":
    case "officePhoneMain":
    case "officePhoneDirect":
      return "tel";

    default:
      return "on";
  }
};

export const getFormControlType = (key: InputType) => {
  switch (key) {
    case "email":
    case "emailAddressWork":
    case "organizationEmail":
      return "email";

    case "password":
    case "newPassword":
    case "confirmPassword":
    case "currentPassword":
      return "password";

    case "phoneNumber":
    case "officePhoneMain":
    case "officePhoneDirect":
      return "tel";

    default:
      return "text";
  }
};

const ContactForm = ({
  inputList,
  onContactUpdate,
  asterisk,
}: ContactFormProps) => {
  const [inputs, setInputs] = useState({});
  const [defaultAddress, setDefaultAddress] = useState<AddressData>();

  const loadDefaultData = () => {
    let defaultInputs = {};

    inputList.forEach((e) => {
      if (e.isName) {
        const defaultName = e.defaultValue as {
          firstName: string;
          lastName: string;
        };
        if (defaultName.firstName || defaultName.lastName) {
          defaultInputs["firstName"] = e.defaultValue?.firstName || "";
          defaultInputs["lastName"] = e.defaultValue?.lastName || "";
        }
      } else if (e.type == "address") {
        setDefaultAddress(e.defaultValue);
      } else {
        defaultInputs[e.type] = e.defaultValue ?? "";
      }
    });
    setInputs(defaultInputs);
  };

  // if default data is will be from api and value changes then update them
  useEffect(() => {
    loadDefaultData();
  }, [inputList]);

  // if address values updates then update contact object of parent

  function handleAddress(address: AddressData) {
    const updatedInputs = {
      ...inputs,
      address,
    };
    setInputs(updatedInputs);
    onContactUpdate(updatedInputs as ContactFormData);
  }

  // if input value changes then update contact object of parent
  function handleChange(
    fieldName: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const updatedInputs = {
      ...inputs,
      address: defaultAddress,
      [fieldName]: e.target.value,
    };
    setInputs(updatedInputs);
    onContactUpdate(updatedInputs as ContactFormData);
  }

  return (
    <>
      {inputList.map((e, index) => {
        if (e.isName) {
          return (
            <div className="row mb-4" key={"contactNameInput"}>
              <Form.Group className="col-6" controlId="firstName">
                <Form.Label className="fw-bold">First Name:</Form.Label>
                <Form.Control
                  type="text"
                  disabled={e.disabled}
                  required={e.required}
                  autoComplete={getAutocompleteType("firstName")}
                  value={inputs["firstName"] ?? ""}
                  onChange={(evt) => handleChange("firstName", evt as any)}
                  isInvalid={e.error != undefined}
                />
                {/* {e.error} */}
              </Form.Group>
              <Form.Group className="col-6" controlId="familyName">
                <Form.Label className="fw-bold">Last Name:</Form.Label>
                <Form.Control
                  type="text"
                  disabled={e.disabled}
                  required={e.required}
                  autoComplete={getAutocompleteType("lastName")}
                  value={inputs["lastName"] ?? ""}
                  onChange={(evt) => handleChange("lastName", evt as any)}
                  isInvalid={e.error != undefined}
                />
                {/* {e.error} */}
              </Form.Group>
            </div>
          );
        }

        if (e.type == "address") {
          return (
            <div key={"contact-address"}>
              <Address
                inputLabel={e.title}
                asterisk={asterisk}
                required={e.required}
                disabled={e.disabled}
                defaultAddress={defaultAddress}
                onAddressChange={(address) => {
                  handleAddress(address);
                }}
                error={e.error as string}
              />
              {/* <p className="font-color-red poppins mb-3">{e?.error || ""}</p> */}
            </div>
          );
        }

        // password enabled types
        if (possiblePasswordTypes.includes(e.type)) {
          return (
            <Form.Group
              className="mb-4"
              controlId={e.type}
              key={`input-${index}`}
            >
              <Form.Label className="fw-bold">
                {e.title}
                <span className="font-color-red">
                  {asterisk && e.required ? "* " : ""}
                </span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  disabled={e.disabled}
                  type={
                    possiblePasswordTypes.includes(e.type) ? "password" : "text"
                  }
                  required={e.required}
                  value={inputs[e.type] ?? ""}
                  onChange={(evt) => handleChange(e.type, evt as any)}
                  isInvalid={e.error != undefined}
                  autoComplete={getAutocompleteType(e.type as any)}
                />
                <button
                  id={`${e.type}-toggle`}
                  style={{
                    zIndex: 1,
                  }}
                  className="bg-white input-group-text rounded-0"
                  aria-label="toggle password visibility"
                  title="toggle visibility"
                  type="button"
                  onKeyDown={(e) => {
                    if (e.key == " " || e.key == "Enter") {
                      e.currentTarget.click();
                      e.preventDefault();
                    }
                  }}
                  onClick={() => {
                    window.password_show_hide(`#${e.type}`);
                  }}
                >
                  <FontAwesomeIcon icon={faEye} id="show_eye" />
                  <FontAwesomeIcon icon={faEyeSlash} id="hide_eye" />
                </button>

                {e.error}
              </InputGroup>
            </Form.Group>
          );
        }
        return (
          <Form.Group
            className="mb-4"
            controlId={e.type}
            key={`input-${index}`}
          >
            <Form.Label className="fw-bold">
              {e.title}
              <span className="font-color-red">
                {asterisk && e.required ? "* " : ""}
              </span>
            </Form.Label>
            <Form.Control
              type={getFormControlType(e.type as any)}
              disabled={e.disabled}
              required={e.required}
              autoComplete={getAutocompleteType(e.type as any)}
              value={e.value ?? inputs[e.type] ?? ""}
              onChange={(evt) => handleChange(e.type, evt as any)}
              isInvalid={e.error != undefined}
            />
            {e.error}
          </Form.Group>
        );
      })}
    </>
  );
};

export default ContactForm;
