import React, { Dispatch, SetStateAction, useState } from "react";
import { Collapse } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  checkPending,
  getFeaturesList,
  isAdmin,
  logout,
  userProfile,
} from "../../redux/auth/index";
import { calculateFeatures } from "utils/CommonUtils";
import { Link } from "react-router-dom";
import { SideIcons } from "utils/IconUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";
declare global {
  interface Window {
    impersonateUser: boolean;
  }
}

interface LeftMenuProps {
  path: string;
  role?: string;
  setShowCompanyDetailModal: Dispatch<SetStateAction<boolean>>;
}

const LeftMenu = ({
  path,
  role = "guest",
  setShowCompanyDetailModal,
  ...props
}: LeftMenuProps) => {
  const isPending = useSelector(checkPending);

  const [isCollapsed, setIsCollapsed] = useState({
    "View and Manage": true,
    Tools: true,
    "SWMS Management": true,
    "Account Setting": true,
    "Coming Soon": false,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const logoutUser = () => {
    dispatch(logout());
    navigate("/home");
  };
  const features = useSelector(getFeaturesList);

  const userData = useSelector(userProfile);
  const userIsAdmin = useSelector(isAdmin);

  const featureMethod = calculateFeatures(features || []);

  const updatedMenuItem = {
    admin: {
      "Master Admin": "/admin-master",
      "Sign out": {
        onClick: () => logoutUser(),
      },
    },
    guest: {
      Home: ["/home"],
      "Getting Started": ["/getting-started"],
      "Pricing & Sign Up": ["/pricing"],
      "Contact Us": ["/contact"],
      Login: ["/auth/login"],
    },
    pah: {
      Home: ["/home"],
      "View and Manage": {
        icon: <SideIcons.SideFile fill="#000" />,
        subRoute: true,
        Jobs: ["/worksite"],
        Employees: ["/staff/employee"],
        Contractors: ["/staff/contractors"],
        "Clients & Contacts": ["/staff/contacts"],
      },
      Tools: {
        icon: <SideIcons.SideTools fill="#000" />,
        subRoute: true,
        "Work Schedule": ["/schedule"],
        "Incident Reports": ["/incident-reports"],
        /* ...(featureMethod?.showIncident && featureMethod?.showIncident()
          ? { "Incident Reports": ["/incident-reports"] }
          : {}), */
        ...(featureMethod?.showJsa && featureMethod?.showJsa()
          ? { "JSA Reports - Review": ["/jsa-reports"] }
          : {}),
        ...(featureMethod?.showAudits && featureMethod?.showAudits()
          ? { "Audit Tool": ["/audits"] }
          : {}),
        ...(featureMethod?.showInductions && featureMethod?.showInductions()
          ? { "Courses/Inductions": ["/induction"] }
          : {}),
        ...(featureMethod?.showNotifications &&
        featureMethod?.showNotifications()
          ? { "Hazard Responses": ["/notification/dashboard"] }
          : {}),
        ...(featureMethod?.showVariations && featureMethod?.showVariations()
          ? { "Worksite Variation": ["/variation"] }
          : {}),
        ...(featureMethod?.showDefects && featureMethod?.showDefects()
          ? {
              "Worksite Defects": ["/defects"],
            }
          : {}),
        ...(featureMethod?.showDocuments && featureMethod?.showDocuments()
          ? { "Worksite info & docs": ["/documents"] }
          : {}),
        ...(featureMethod?.showPssc && featureMethod?.showPssc()
          ? { "Pre-Start Safety Check": ["/pssc"] }
          : {}),
        ...(featureMethod?.showPst && featureMethod?.showPst()
          ? { "Pre-Start Talks": ["/pst"] }
          : {}),
        ...(featureMethod?.showPurchases && featureMethod?.showPurchases()
          ? { "Record Purchases": ["/record-purchases"] }
          : {}),
        ...(featureMethod?.showProgress && featureMethod?.showProgress()
          ? { Reports: ["/progress-reports"] }
          : {}),
      },
      ...(featureMethod?.showSwms && featureMethod?.showSwms()
        ? {
            "SWMS Management": {
              icon: <SideIcons.SideReport />,
              subRoute: true,
              ...(featureMethod?.showSwmscreate &&
              featureMethod?.showSwmscreate()
                ? { "Create SWMS": ["/swms"] }
                : {}),
              ...(featureMethod?.showSwmsview && featureMethod?.showSwmsview()
                ? { "View SWMS": ["/view-swms"] }
                : {}),
            },
          }
        : {}),
      "Account Setting": {
        icon: <SideIcons.SideGear />,
        subRoute: true,
        "My Account Details": isPending ? ["/user/account"] : ["/user"],
        "User Account Management": ["/manage-features"],
        ...(userIsAdmin && { "Admin Tools": ["/admin-tools"] }),
      },
      Help: ["/help"],
      "Contact Us": ["/contact"],
      "Coming Soon": {
        subRoute: true,
        "Gantt Chart": ["/gantt"],
        "Quote Tool": ["/quote-tool"],
        "Generate Invoice": ["/invoice/generate"],
      },
      "Sign Out": {
        onClick: logoutUser,
      },
      ...(userIsAdmin && { "SWMS Admin Panel": ["/admin-swms"] }),
    },

    staff: {
      Home: ["/home"],
      "My Jobs": ["/my-jobs"],
      "Courses/Inductions": ["/induction"],
      //temp comment for prod
      Help: ["/help"],
      "Contact Us": ["/contact"],
      "My Account": ["/user"],
      "Sign Out": {
        onClick: logoutUser,
      },
    },
  };

  const allowedRouteName = ["My Account", "Contact Us", "Help", "Sign Out"];
  const staffType = ["SAH", "NAH", "SAH_LH"];
  const userNotActive = ["suspended", "inactive"];

  const userType = (role: string) => {
    if (role.toLowerCase().includes("pah")) {
      return "pah";
    } else if (staffType.includes(role.toUpperCase())) {
      return "staff";
    } else {
      return role;
    }
  };

  const items = updatedMenuItem[userType(role)];
  const currentPath = location.pathname;

  const isRouteActive = (path: any) => {
    let active = false;
    if (path && typeof path[0] === "string") {
      active = path.reduce((prev, curr) => {
        if (currentPath.indexOf(curr) === 0) return true;
        else return prev;
      }, false);
    }

    return active;
  };

  const clickHandler = (path: any) => {
    if (path?.subRoute) return;
    if (path && typeof path[0] === "string") {
      // hashHistory.push(path);
      const menu = document.getElementsByClassName("left-section");
      if (menu.length > 0) {
        menu[0].classList.add("close");
        document.body.classList.remove("body-hidden");
      }
      if (
        userNotActive.includes(userData?.status) &&
        !path[0].includes("user") &&
        userData.roles.includes("ROOT_PAH")
      ) {
        console.log("not active");
      } else {
        // navigate(path[0]);
        // window.scrollTo(0, 0);
      }
    } else {
      path.onClick();
    }
  };

  return (
    <div
      className="h-100"
      style={{
        overflowY: "auto",
        backgroundColor: "#b8b8b8",
        width: "365px",
        cursor: "auto",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <nav
        className="side-navbar mb-4 pb-5 active-nav d-flex justify-content-between flex-wrap flex-column"
        id="sidebar"
      >
        <ul className="nav flex-column text-white w-100" role={"menubar"}>
          {Object.keys(items || []).map((title, i) => {
            const path = items[title];
            let active = isRouteActive(path);

            return (
              <div key={`${title}-${i}`}>
                <Link
                  title={title}
                  role={path?.subRoute ? "button" : ""}
                  to={path?.subRoute ? "" : path[0] || "/home"}
                  onKeyPress={(e) => {
                    if (e.key == "Enter" || e.key == " ") {
                      clickHandler(path);
                      if (path?.subRoute) {
                        setIsCollapsed({
                          ...isCollapsed,
                          [title]: !isCollapsed[title],
                        });
                      }
                    }
                    e.preventDefault();
                  }}
                  className={`${
                    path?.subRoute ? "nav-button" : "nav-link"
                  }  text-white text-decoration-none d-block text-start ${
                    active || (i == 0 && currentPath == "/") ? "active" : ""
                  }`}
                  onClick={(e) => {
                    if (path?.subRoute) {
                      e.preventDefault();
                      setIsCollapsed({
                        ...isCollapsed,
                        [title]: !isCollapsed[title],
                      });
                    } else {
                      if (!allowedRouteName.includes(title) && isPending) {
                        setShowCompanyDetailModal(true);
                      } else {
                        clickHandler(path);
                      }
                    }
                  }}
                >
                  {path?.subRoute ? (
                    <div
                      aria-expanded={isCollapsed[title]}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <div className="d-flex align-items-center gap-2">
                        {path.icon}
                        <span className="h3-text work-sans">{title}</span>
                      </div>
                      <FontAwesomeIcon
                        size="lg"
                        icon={faChevronCircleDown}
                        color="#5b5b5b"
                        style={{
                          rotate: isCollapsed[title] ? "0deg" : "-90deg",
                          transition: "rotate 0.3s ease-in",
                        }}
                      />
                    </div>
                  ) : (
                    <span className="h3-text work-sans">{`${title}`}</span>
                  )}
                </Link>
                {path?.subRoute && (
                  <Collapse in={isCollapsed[title]}>
                    <div className="sub-nav-link">
                      {Object.keys(path || [])
                        .filter((e) => e !== "icon")
                        .map((subTitle, id) => {
                          const subPath = path[subTitle];
                          if (subPath === true) return;
                          const subActive = isRouteActive(subPath);

                          if (title && subActive && !isCollapsed[title]) {
                            setIsCollapsed({
                              ...isCollapsed,
                              [title]: true,
                            });
                          }
                          return (
                            <Link
                              title={title}
                              to={subPath[0]}
                              key={`${subTitle}-${id}`}
                              className={`nav-link text-decoration-none d-block text-white text-start ${
                                subActive || (i == 0 && currentPath == "/")
                                  ? "active"
                                  : ""
                              }`}
                              onKeyPress={(e) => {
                                if (e.key == "Enter" || e.key == " ") {
                                  clickHandler(subPath);
                                }
                                e.preventDefault();
                              }}
                              onClick={(e) => {
                                if (
                                  !allowedRouteName.includes(title) &&
                                  isPending
                                ) {
                                  e.preventDefault();
                                  setShowCompanyDetailModal(true);
                                } else {
                                  clickHandler(subPath);
                                }
                              }}
                            >
                              <span className="h3-text work-sans">{`${subTitle}`}</span>
                            </Link>
                          );
                        })}
                    </div>
                  </Collapse>
                )}
              </div>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default LeftMenu;
