import { AuditSvgsRaw } from "utils/IconUtils";
import { doFetch } from "./fetcher";
import { pageMetaData } from "./notification";
import {
  Contact,
  FileDto,
  JobDto,
  JobStaffDto,
  MinimalUserDto,
  ProjectDto,
  UserDto,
  Worksite,
} from "./users";

export enum AnswerResponseEnum {
  BOOLEAN = "boolean",
  MULTI_RADIO = "multi_radio",
  MULTI_CHECKLIST = "multi_checklist",
  MULTI_SELECT = "multi_select",
  TEXT = "text",
  NUMBER = "number",
  SIGNATURE = "signature",
  SLIDER = "slider",
  DATE = "date",
  DATE_TIME = "datetime",
  TIME = "time",
  AUDIO = "audio",
  PHOTO = "photo",
  FILE = "file",
  SHOW_FILE = "show_file",
  SHOW_LOCATION = "show_location",
}

export enum FormatterEnum {
  ABN = "abn",
  PHONE = "phone",
  PERCENTAGE = "percentage",
  CURRENCY = "currency",
  NONE = "none",
}

export const arrResponseItemTypes = [
  "boolean",
  "multi_radio",
  "multi_checklist",
  "multi_select",
  "text",
  "number",
  "signature",
  "slider",
  "date",
  "datetime",
  "time",
  "audio",
  "photo",
  "file",
  "show_file",
  "show_location",
] as const;
export type AuditResponseItemType = (typeof arrResponseItemTypes)[number];

export declare type ResponseTypesDto = {
  [s: string]: {
    icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
    title: string;
    type: AuditResponseItemType;
  }[];
};

export declare type QuestionTypesDto = {
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  title: string;
  type: string;
};

export const ResponseTypes: ResponseTypesDto = {
  "Premade Questions": [
    {
      icon: AuditSvgsRaw.QuestionType0Select,
      title: "Select",
      type: undefined,
    },
    {
      icon: AuditSvgsRaw.QuestionType1QASub1,
      title: "Yes/No",
      type: AnswerResponseEnum.BOOLEAN,
    },
    {
      icon: AuditSvgsRaw.QuestionType1QASub2,
      title: "Multiple Choice",
      type: AnswerResponseEnum.MULTI_RADIO,
    },
    {
      icon: AuditSvgsRaw.QuestionType1QASub3,
      title: "Checklist",
      type: AnswerResponseEnum.MULTI_CHECKLIST,
    },
  ],
  "Text Fields": [
    {
      icon: AuditSvgsRaw.QuestionType0Select,
      title: "Select",
      type: undefined,
    },
    {
      icon: AuditSvgsRaw.QuestionType2TextEntrySub1,
      title: "Text Field",
      type: AnswerResponseEnum.TEXT,
    },
    {
      icon: AuditSvgsRaw.QuestionType2TextEntrySub2,
      title: "Number Field",
      type: AnswerResponseEnum.NUMBER,
    },
  ],
  "Input and Selectors": [
    {
      icon: AuditSvgsRaw.QuestionType0Select,
      title: "Select",
      type: undefined,
    },
    {
      icon: AuditSvgsRaw.QuestionType3InfoInputSub1,
      title: "Record Signature",
      type: AnswerResponseEnum.SIGNATURE,
    },
    {
      icon: AuditSvgsRaw.QuestionType3InfoInputSub2,
      title: "Slider",
      type: AnswerResponseEnum.SLIDER,
    },
    {
      icon: AuditSvgsRaw.QuestionType3InfoInputSub3,
      title: "Date/Time Selector",
      type: AnswerResponseEnum.DATE_TIME,
    },
    {
      icon: AuditSvgsRaw.QuestionType3InfoInputSub4,
      title: "Dropdown list",
      type: AnswerResponseEnum.MULTI_SELECT,
    },
  ],
  "Request Media Upload": [
    {
      icon: AuditSvgsRaw.QuestionType0Select,
      title: "Select",
      type: undefined,
    },
    {
      icon: AuditSvgsRaw.QuestionType4UploadMediaSub1,
      title: "Audio Recording",
      type: AnswerResponseEnum.AUDIO,
    },
    {
      icon: AuditSvgsRaw.QuestionType4UploadMediaSub2,
      title: "Photo / Camera",
      type: AnswerResponseEnum.PHOTO,
    },
    {
      icon: AuditSvgsRaw.QuestionType4UploadMediaSub3,
      title: "Document File",
      type: AnswerResponseEnum.FILE,
    },
  ],
  "Display Information": [
    {
      icon: AuditSvgsRaw.QuestionType0Select,
      title: "Select",
      type: undefined,
    },
    {
      icon: AuditSvgsRaw.QuestionType5DisplayInfoSub1,
      title: "Image/Document",
      type: AnswerResponseEnum.SHOW_FILE,
    },
    {
      icon: AuditSvgsRaw.QuestionType5DisplayInfoSub2,
      title: "Current Location Map",
      type: AnswerResponseEnum.SHOW_LOCATION,
    },
  ],
};

export const QuestionTypes: QuestionTypesDto[] = [
  {
    icon: AuditSvgsRaw.QuestionType0Select,
    title: "Select",
    type: undefined,
  },
  {
    icon: AuditSvgsRaw.QuestionType1QA,
    title: "Premade Questions",
    type: "Premade Questions",
  },
  {
    icon: AuditSvgsRaw.QuestionType2TextEntry,
    title: "Text Fields",
    type: "Text Fields",
  },
  {
    icon: AuditSvgsRaw.QuestionType3InfoInput,
    title: "Input and Selectors",
    type: "Input and Selectors",
  },
  {
    icon: AuditSvgsRaw.QuestionType4UploadMedia,
    title: "Request Media Upload",
    type: "Request Media Upload",
  },
  {
    icon: AuditSvgsRaw.QuestionType5DisplayInfo,
    title: "Display Information",
    type: "Display Information",
  },
];

export const arrCustomFormatterTypes = [
  "abn",
  "phone",
  "percentage",
  "currency",
  "none",
] as const;
export type AuditTemplateSectionElementCustomFormatterType =
  (typeof arrCustomFormatterTypes)[number];

export const arrSliderTypes = ["number", "text"] as const;
export type AuditTemplateSliderElementType = (typeof arrSliderTypes)[number];

export const arrSliderSelectionTypes = [
  "priority",
  "quality",
  "percentage",
  "one-to-ten",
  "custom",
] as const;
export type AuditTemplateSliderSelectionType =
  (typeof arrSliderSelectionTypes)[number];

export const arrPriority = [
  "none",
  "low",
  "moderate",
  "high",
  "urgent",
] as const;
export type AuditPriorityType = (typeof arrPriority)[number];

export const arrEntityAssignmentFrequency = [
  "once",
  "daily",
  "weekly",
  "monthly",
  "yearly",
] as const;
export type AuditEntityAssignmentFrequencyType =
  (typeof arrEntityAssignmentFrequency)[number];

export interface CreateAuditTemplateSectionElementConstraintsDto {
  /** For slider */
  minValue?: number;
  maxValue?: number;
  sliderType?: AuditTemplateSliderElementType;
  sliderSelectionType?: AuditTemplateSliderSelectionType;

  /* For date picker control */
  dateSelectRange?: boolean; // Select date range

  /** For signature fields */
  signatureRequireName?: boolean;
  signatureRequireTerms?: boolean;

  /** For text fields */
  textMaxLength?: number;
  textShortResponse?: boolean;

  /** For file upload fields (file/photo) */
  fileAllowMultipleUploads?: boolean;

  /** For audio fields */
  audioAllowTextEntry?: boolean;
}

export interface CreateAuditTemplateSectionElementOptionDto {
  id?: string; //  only for local
  /** Value of option (ie: label for user to select)  */
  value: string;

  error?: {
    value: string;
  };

  /** Notify management if this option was selected by the person doing the response */
  notifyOnResponse?: boolean;

  /** Order of option releative to question */
  order: number;
}

export interface CreateAuditTemplateSectionElementDto {
  id?: string;
  questionType?: string; // for local use only
  name: string;
  order: number;
  type: string;
  /** The label for the question */
  prompt: string;

  error?: {
    prompt: string;
    secondaryText: string;
    file: string;
  };

  /** The options for the question (if applicable) - multiple choice */
  options: CreateAuditTemplateSectionElementOptionDto[];

  /** Is this question required */
  required: boolean;

  /** Notify management upon response to this question? */
  notifyOnResponse: boolean;
  file?: FileDto; //only for local
  /** Upload a file for this element - show_file */
  fileId?: number;
  /**
   * Custom formatters are used for app where things like an ABN require special formatting:
   * for example; ABN's require 2/3/3/3 format with spaces in between.
   * Mobile numbers require 4/3/3 format with spaces in between.
   * Percentage requires '%' on the end.
   * Date requires no 'time' value on the end.
   *
   * Note: Formatters are for visual display only; but the value stored in the database will be the raw value (ie: number; datetime; etc)
   */
  customFormatter: AuditTemplateSectionElementCustomFormatterType;

  constraints: CreateAuditTemplateSectionElementConstraintsDto;
  /**
   * Primarily used for the 'Terms and Conditions' for signature type when we want to show the user a message.
   *
   * Perhaps we may also use it if we want to show 'non-header' text style for the 'show information' component too.
   */

  secondaryText?: string;
}

export interface CreateAuditTemplateSectionDto {
  id?: string; // for local use only uuid
  /** Name of section in Audit ('Question 1').  Will be text in accordion in app. */
  name: string;
  order: number;
  clone: boolean;
  cloneButtonText: string;
  /** Questions/Input elements shown in app */
  elements: CreateAuditTemplateSectionElementDto[];
}

export interface CreateAuditTemplateDto {
  name: string;
  description: string;
  error?: {
    name: string;
    description: string;
  };
  /** So we can track when the 'copy audit' option was used. */
  derivedFromAuditTemplateId?: number;

  /** Sections/Questions that are present within the audit. */
  sections: CreateAuditTemplateSectionDto[];
}

export interface auditElementDto {
  id: string;
  name: string;
  hash: string;
  order: number;
  file?: FileDto;
  type: AuditResponseItemType;
  prompt: string;
  options: CreateAuditTemplateSectionElementOptionDto[];
  required: boolean;
  notifyOnResponse: boolean;
  customFormatter: AuditTemplateSectionElementCustomFormatterType;
  constraints: CreateAuditTemplateSectionElementConstraintsDto;
  secondaryText: string | null;
}

export interface auditSectionDto {
  id: string;
  name: string;
  order: number;
  clone: boolean;
  cloneButtonText: string | null;
  elements: auditElementDto[];
}

export interface AuditTemplateDto {
  name: string;
  description: string;
  id: number;
  activeAuditLinks: number;
  createdByUser: UserDto;
  createdOn: string;
  lastSubmissionDate: string | null;
  /** Sections/Questions that are present within the audit. */
  sections: auditSectionDto[];
}

export interface AuditTemplateCompany {
  company_name: string;
  companyLogo: FileDto;
  id: string;
}
export interface AuditResponseTemplateDto {
  auditTemplate: AuditTemplateDto;
  brandingVisible: boolean;
  company: AuditTemplateCompany;
}

export interface assignAuditRequest {
  auditTemplateId: number;
  worksiteId: number;
  projectId: number;
  jobId?: number;
}

export interface AuditSiteManagementEmailsDto {
  companyId: number;
  email: string;
  id: number;
  userId: number;
}
export interface AuditSiteAssignmentDto {
  id: number;
  availability: string;
  worksiteId: number;
  worksite: Worksite;
  projectId: number;
  project: ProjectDto;
  jobId?: number | null;
  job: JobDto | null;
  auditTemplate: AuditTemplateDto;
  status: string;
  visibleToAllStaff: boolean;
  emails: AuditSiteManagementEmailsDto[];
}

export interface CreateAuditEntityAssignment {
  auditTemplateId: number;
  /** Represents the WPJ */
  auditSiteAssignmentId: number;
  userId?: number;
  companyId?: number;
  hideResponses?: boolean;
  startDate: string;
  endDate: string;
  time: string;
  frequency: AuditEntityAssignmentFrequencyType;
  priority: AuditPriorityType;
  instructions?: string;
}

export interface AuditResponseSummaryDto {
  id: number;
  name: string;
  description: string;
  responseCount: number;
  mostRecentResponseDate: Date;
  auditTemplateCreatedOn: Date;
  actionRequiredCount: number;
  noActionRequiredCount: number;
}

export interface AuditAssignmentEntityDto {
  auditSiteAssignmentId: number;
  auditTemplate: AuditTemplateDto;
  endDate: string;
  frequency: AuditEntityAssignmentFrequencyType;
  hideResponses: boolean;
  id: number;
  instructions: string;
  jobId: number;
  jobName: string;
  lastSubmissionDate: string;
  priority: AuditPriorityType;
  projectId: number;
  projectName: string;
  startDate: string;
  status: string;
  time: string;
  worksiteId: number;
  worksiteName: string;
  createdBy: string;
  createdOn: string;
}

export interface UpdateAuditAssignmentDto {
  visibleToAllStaff: boolean;
}

export interface assignEmailToAssignmentDto {
  email: string;
  op: "add" | "remove";
}

export interface auditQueryOptions {
  page?: number;
  take?: number;
  all?: boolean;
  name?: string | undefined;
  userId?: number | undefined;
  createdOnStart?: string | undefined;
  createdOnEnd?: string | undefined;
  auditType?: "audit" | "jsa" | "incident";
  order?: "asc" | "desc";
}

export class AuditResponseItemOptionValueDto {
  auditResponseItemId: number;
  auditTemplateSectionElementOptionId: string;
  id: string;
  order: number;
  managementNotificationCount: number;
  selected: boolean;
  value: string;
  notifyOnResponse: false;
}

export interface AuditResponseItemDto {
  id: number;
  auditResponseId: number;
  auditResponseSectionId: string;
  auditTemplateId: number;
  auditTemplateSectionId: string;
  auditTemplateSectionElementId: string;
  hash: string;
  type: AuditResponseItemType;
  files: FileDto[];
  stringValue?: string;
  numericValue?: number;
  booleanValue?: boolean;
  dateValue?: string;
  dateFromValue?: string;
  customFormatter?: AuditTemplateSectionElementCustomFormatterType;
  dateToValue?: string;
  multiSelectValueOptions?: AuditResponseItemOptionValueDto[];
  managementNotificationCount: number;
}

export interface AuditResponseLinkItemDto {
  auditTemplateSectionElementId: string;
  hash: string;
  type: string;
  files: number[];
  stringValue: string | null;
  numericValue: number | null;
  booleanValue: boolean | null;
  dateValue: string | null;
  dateFromValue: string | null;
  dateToValue: string | null;
  options: Array<{
    id: string;
    value: string;
    selected: boolean;
  }>;
}

export interface CreateAuditRequestSectionMapDto {
  auditResponseSectionId: string;
  auditTemplateSectionId: string;
  order: number;
}

export interface AuditResponseRequestDto {
  auditTemplateId: number;
  auditEntityAssignmentId?: number;
  auditSiteAssignmentId?: number;
  auditGenericAssignmentId?: number;
  auditLinkAssignmentUuid?: string;
  worksiteId?: number;
  projectId?: number;
  jobId?: number;
  items: AuditResponseLinkItemDto[];
  sections: CreateAuditRequestSectionMapDto[];
  geo_lat: number;
  geo_long: number;
}

export interface CreateAuditResponseSectionMapDto {
  id: string;
  auditTemplateSectionId: string;
  order: number;
}

export interface AuditResponseDto {
  auditTemplate?: AuditTemplateDto; // only local
  id: number;
  auditTemplateId: number;
  auditEntityAssignmentId: number;
  auditSiteAssignmentId: number;
  worksiteId: number;
  worksiteName: string;
  projectId: number;
  projectName: string;
  jobId: number;
  jobName: string;
  userId: number;
  companyId: number;
  createdOn: string;
  managementNotificationCount: number;
  items: AuditResponseItemDto[];
  respondentName: string;
  sections: CreateAuditResponseSectionMapDto[];
  geo: { lat: number; long: number };
}

export class AuditStatsMonthOnMonthDto {
  YearMonth: string;
  auditName: string;
  auditTemplateId: number;
  currentOrganizationId: number;
  Count: number;
}

export class AuditStatsMonthOnMonthByUserDto {
  YearMonth: string;
  auditName: string;
  auditTemplateId: number;
  userId: number;
  employeeName: string;
  companyName: string;
  organizationId: number;
  currentOrganizationId: number;
  Count: number;
}

export interface AuditStatsDto {
  monthOnMonth: AuditStatsMonthOnMonthDto[];
  monthOnMonthByUser: AuditStatsMonthOnMonthByUserDto[];
}

export interface AuditEntityAssignmentDto {
  id: number;
  auditTemplate: AuditTemplateDto;
  auditSiteAssignmentId: number;
  worksiteId: number;
  worksiteName: string;
  projectId: number;
  projectName: string;
  jobId: number;
  jobName: string;
  startDate: Date;
  endDate: Date;
  lastSubmissionDate: Date;
  hideResponses: boolean;
  status: "active" | "inactive";
  time: string;
  frequency: AuditEntityAssignmentFrequencyType;
  priority: AuditPriorityType;
  instructions: string;
  createdBy: string;
  createdOn: Date;
}

export interface AuditEntityAssignmentSummaryDto {
  name: string;
  mostRecentAssignmentStartDate: Date;
  mostRecentAssignmentEndDate: Date;
  mostRecentPriority: AuditPriorityType;
  mostRecentAssignedBy: string;
  totalAssignments: number;
  assignments: AuditEntityAssignmentDto[];
}

export interface auditResponseQuery {
  page?: number;
  take?: number;
  all?: boolean;
  order?: "asc" | "desc";
  worksites?: number[];
  projects?: number[];
  jobs?: number[];
  contractors?: number[];
  employees?: number[];
  activeState?: "active";
  createdOnStart?: string;
  createdOnEnd?: string;
  auditId?: number[];
  auditType?: "audit" | "jsa" | "incident";
}

export interface AuditLinkQuery {
  page?: number;
  take?: number;
  all?: boolean;
  order?: "asc" | "desc";
  description?: string;
  active?: boolean;
  expiryDateStart?: string;
  expiryDateEnd?: string;
  recipientEmails?: string;
  auditTemplateIds?: number[];
}

export interface AuditLinkCapacity {
  linksAvailablePermanent: number;
  linksAvailableTemporary: number;
  activePermanentLinks: number;
  activeTemporaryLinks: number;
  totalPermanentLinks: number;
  totalTemporaryLinks: number;
}

export const AuditLinkTypesArray = ["temporary", "permanent"] as const;
export type AuditLinkTypes = (typeof AuditLinkTypesArray)[number];

export interface AuditLinkAssignmentManagementEmailsDto {
  id: number;
  email: string;
  auditLinkAssignmentId: number;
  createdOn: string;
  updatedOn: string;
}

export interface AuditLinkAssignmentRecipientDto {
  id: number;
  auditLinkAssignmentId: number;
  name: string;
  userId: number;
  contactId: number;
  expiryDate: string;
  active: boolean;
  visibility: "public" | "private";
  submissionCount: number;
  submissionLimit: number;
  lastSubmissionDate: string;
  uuid: string;
  user: MinimalUserDto;
  contact: Contact;
  companyId: number;
  email: string;
  createdOn: Date;
  updatedOn: Date;
}

export interface AuditWeblinkDto {
  createdBy: string;
  id: number;
  auditName: string;
  auditTemplateId: number;
  description: string;
  type: AuditLinkTypes;
  active: boolean;
  expiryDate: string;
  submissionLimit: number;
  createdOn: string;
  updatedOn: string;
  emails: AuditLinkAssignmentManagementEmailsDto[];
  recipients: AuditLinkAssignmentRecipientDto[];
  brandingVisible: boolean;
}

export interface CreateAuditManagementEmailDto {
  email: string;
  op: "add" | "remove";
}

export interface AuditLinkCreateRecipientDto {
  userId?: number;
  contactId?: number;
  companyId?: number;
  email?: string;
  op: "add" | "remove";
}

export interface AuditLinkUpdateDto {
  id: number;
  description?: string;
  brandingVisible?: boolean;
  emails?: CreateAuditManagementEmailDto[];
  active?: boolean;
  expiryDate?: string;
  recipients?: AuditLinkCreateRecipientDto[];
}

export interface AuditLinkCreateDto {
  auditTemplateId: number;
  description: string;
  emails: CreateAuditManagementEmailDto[];
  type: AuditLinkTypes;
  expiryDate: string;
  recipients: AuditLinkCreateRecipientDto[];
  submissionLimit?: number;
  brandingVisible: boolean;
}

export interface AuditReportQueryDto {
  worksites?: number[];
  projects?: number[];
  jobs?: number[];
  siteAssignments?: number[];
  entityAssignments?: number[];
  templates?: number[];
  contractors?: number[];
  employees?: number[];
  createdOnStart?: string;
  createdOnEnd?: string;
  auditType?: "incident" | "jsa" | "audit";
}

export const auditApis = {
  getPdfReportFile: (data: AuditReportQueryDto) =>
    doFetch(`/api/v3/export/audit/pdf`, "POST", data),
  uploadAuditFile: (data: BodyInit, id: string): Promise<FileDto> =>
    doFetch(`/api/v3/files/audit/${id}`, "POST", data),
  getAuditWeblinkById: (id: number): Promise<AuditWeblinkDto> =>
    doFetch(`/api/v3/audit/link/${id}`, "GET"),
  getAllAuditLinks: ({
    active,
    expiryDateStart,
    order,
    expiryDateEnd,
    recipientEmails,
    auditTemplateIds,
  }: AuditLinkQuery): Promise<AuditWeblinkDto[]> =>
    doFetch(
      `/api/v3/audit/link?all=${true}${order ? `&order=${order}` : ""}${
        active ? `&active=${active}` : ""
      }${expiryDateStart ? `&expiryDateStart=${expiryDateStart}` : ""}${
        expiryDateEnd ? `&expiryDateEnd=${expiryDateEnd}` : ""
      }${recipientEmails ? `&recipientEmails=${recipientEmails}` : ""}${
        auditTemplateIds?.filter((e) => e).length > 0
          ? `${auditTemplateIds.reduce(
              (prev, curr) => prev + `&auditTemplateIds=${curr}`,
              ""
            )}`
          : ""
      }`,
      "GET"
    ),
  getAuditLinksForUser: ({
    page,
    take,
    order,
    active,
    expiryDateStart,
    expiryDateEnd,
    recipientEmails,
    auditTemplateIds,
  }: AuditLinkQuery): Promise<pageMetaData<AuditWeblinkDto[]>> =>
    doFetch(
      `/api/v3/audit/link?all=${false}${order ? `&order=${order}` : ""}${
        page ? `&page=${page}` : ""
      }${take ? `&take=${take}` : ""}${active ? `&active=${active}` : ""}${
        expiryDateStart ? `&expiryDateStart=${expiryDateStart}` : ""
      }${expiryDateEnd ? `&expiryDateEnd=${expiryDateEnd}` : ""}${
        recipientEmails ? `&recipientEmails=${recipientEmails}` : ""
      }${
        auditTemplateIds?.filter((e) => e).length > 0
          ? `${auditTemplateIds.reduce(
              (prev, curr) => prev + `&auditTemplateIds=${curr}`,
              ""
            )}`
          : ""
      }`,
      "GET"
    ),
  updateAuditWeblink: (data: AuditLinkUpdateDto): Promise<AuditWeblinkDto> =>
    doFetch(`/api/v3/audit/link`, "PUT", data),
  createAuditWeblink: (data: AuditLinkCreateDto): Promise<AuditWeblinkDto> =>
    doFetch(`/api/v3/audit/link`, "POST", data),
  getAuditWeblinkCapacity: (): Promise<AuditLinkCapacity> =>
    doFetch(`/api/v3/audit/link/capacity`, "GET"),

  getAuditByUUID: (uuid: string): Promise<AuditResponseTemplateDto> =>
    doFetch(`/api/v3/audit/link/public/${uuid}`, "GET"),
  postAuditResponse: (payload: AuditResponseRequestDto) =>
    doFetch("/api/v3/audit/response/web", "POST", payload),
  createAudit: (request: CreateAuditTemplateDto): Promise<AuditTemplateDto> =>
    doFetch("/api/v3/audit", "POST", request),
  getAudits: ({
    page,
    take,
    all,
    name,
    order,
    userId,
    createdOnStart,
    createdOnEnd,
    auditType,
  }: auditQueryOptions): Promise<pageMetaData<AuditTemplateDto[]>> =>
    doFetch(
      `/api/v3/audit?all=${all}${order ? `&order=${order}` : ""}${
        auditType ? `&auditType=${auditType}` : ""
      }${page ? `&page=${page}` : ""}${take ? `&take=${take}` : ""}${
        name ? `&name=${name}` : ""
      }${createdOnStart ? `&createdOnStart=${createdOnStart}` : ""}${
        createdOnEnd ? `&createdOnEnd=${createdOnEnd}` : ""
      }${userId ? `&userId=${userId}` : ""}`,
      "GET"
    ),
  getAllAudit: (): Promise<AuditTemplateDto[]> =>
    doFetch("/api/v3/audit?all=true", "GET"),
  getAllJSAAudit: (): Promise<AuditTemplateDto[]> =>
    doFetch("/api/v3/audit?auditType=jsa", "GET"),
  getAuditById: (auditId: number): Promise<AuditTemplateDto> =>
    doFetch(`/api/v3/audit/${auditId}`, "GET"),
  getAuditLocations: (
    auditId: number,
    {
      page,
      take,
      all,
      name,
      order,
      userId,
      createdOnStart,
      createdOnEnd,
    }: auditQueryOptions
  ): Promise<pageMetaData<AuditSiteAssignmentDto[]>> =>
    doFetch(
      `/api/v3/audit/${auditId}/assign/location?all=${all}${
        order ? `&order=${order}` : ""
      }${page ? `&page=${page}` : ""}${take ? `&take=${take}` : ""}${
        name ? `&name=${name}` : ""
      }${createdOnStart ? `&createdOnStart=${createdOnStart}` : ""}${
        createdOnEnd ? `&createdOnEnd=${createdOnEnd}` : ""
      }${userId ? `&userId=${userId}` : ""}`,
      "GET"
    ),
  getAuditSummary: ({
    page,
    take,
    all,
    worksites,
    projects,
    jobs,
    contractors,
    employees,
    createdOnStart,
    auditType,
    order,
    createdOnEnd,
  }: auditResponseQuery): Promise<pageMetaData<AuditResponseSummaryDto[]>> =>
    doFetch(
      `/api/v3/audit/response/summary?all=${all}${
        order ? `&order=${order}` : ""
      }${auditType ? `&auditType=${auditType}` : ""}${
        page ? `&page=${page}` : ""
      }${take ? `&take=${take}` : ""}${
        worksites?.filter((e) => e).length > 0
          ? `${worksites.reduce(
              (prev, curr) => prev + `&worksites=${curr}`,
              ""
            )}`
          : ""
      }${
        projects?.filter((e) => e).length > 0
          ? `${projects.reduce((prev, curr) => prev + `&projects=${curr}`, "")}`
          : ""
      }${
        jobs?.filter((e) => e).length > 0
          ? `${jobs.reduce((prev, curr) => prev + `&jobs=${curr}`, "")}`
          : ""
      }${
        contractors?.filter((e) => e).length > 0
          ? `${contractors.reduce(
              (prev, curr) => prev + `&contractors=${curr}`,
              ""
            )}`
          : ""
      }${
        employees?.filter((e) => e).length > 0
          ? `${employees.reduce(
              (prev, curr) => prev + `&employees=${curr}`,
              ""
            )}`
          : ""
      }${createdOnStart ? `&createdOnStart=${createdOnStart}` : ""}${
        createdOnEnd ? `&createdOnEnd=${createdOnEnd}` : ""
      }`,
      "GET"
    ),
  getAuditResponse: ({
    auditId,
    page,
    take,
    order,
    all,
    worksites,
    projects,
    jobs,
    contractors,
    employees,
    createdOnStart,
    createdOnEnd,
    auditType,
  }: auditResponseQuery): Promise<pageMetaData<AuditResponseDto[]>> =>
    doFetch(
      `/api/v3/audit/response?all=${all}${order ? `&order=${order}` : ""}${
        auditType ? `&auditType=${auditType}` : ""
      }${
        auditId.filter((e) => e).length > 0
          ? `${auditId.reduce((prev, curr) => prev + `&templates=${curr}`, "")}`
          : ""
      }${page ? `&page=${page}` : ""}${take ? `&take=${take}` : ""}${
        worksites?.filter((e) => e).length > 0
          ? `${worksites.reduce(
              (prev, curr) => prev + `&worksites=${curr}`,
              ""
            )}`
          : ""
      }${
        projects?.filter((e) => e).length > 0
          ? `${projects.reduce((prev, curr) => prev + `&projects=${curr}`, "")}`
          : ""
      }${
        jobs?.filter((e) => e).length > 0
          ? `${jobs.reduce((prev, curr) => prev + `&jobs=${curr}`, "")}`
          : ""
      }${
        contractors?.filter((e) => e).length > 0
          ? `${contractors.reduce(
              (prev, curr) => prev + `&contractors=${curr}`,
              ""
            )}`
          : ""
      }${
        employees?.filter((e) => e).length > 0
          ? `${employees.reduce(
              (prev, curr) => prev + `&employees=${curr}`,
              ""
            )}`
          : ""
      }${createdOnStart ? `&createdOnStart=${createdOnStart}` : ""}${
        createdOnEnd ? `&createdOnEnd=${createdOnEnd}` : ""
      }`,
      "GET"
    ),
  postAuditLocation: (
    request: assignAuditRequest
  ): Promise<AuditSiteAssignmentDto> =>
    doFetch(`/api/v3/audit/assign/location`, "POST", request),
  getAuditLocationById: (locationId: number): Promise<AuditSiteAssignmentDto> =>
    doFetch(`/api/v3/audit/assign/location/${locationId}`, "GET"),
  updateAuditLocation: (
    locationId: number,
    payload: UpdateAuditAssignmentDto
  ): Promise<AuditSiteAssignmentDto> =>
    doFetch(`/api/v3/audit/assign/location/${locationId}`, "PUT", payload),
  assignEmailToLocation: (
    locationId: number,
    payload: assignEmailToAssignmentDto
  ) => doFetch(`/api/v3/audit/assign/${locationId}/emails`, "POST", payload),
  assignEntityToAudit: (payload: CreateAuditEntityAssignment) =>
    doFetch(`/api/v3/audit/assign/user`, "POST", payload),
  getAuditEntityByUser: () => doFetch(`/api/v3/audit/assign/user`, "GET"),
  getAuditEntityByAssignmentSite: (
    auditId: number,
    siteId: number,
    { all, take, page, activeState }: auditResponseQuery
  ): Promise<pageMetaData<AuditEntityAssignmentSummaryDto[]>> =>
    doFetch(
      `/api/v3/audit/${auditId}/assign/entity/summary?siteAssignmentId=${siteId}&all=${all}${
        activeState ? `&activeState=${activeState}` : ""
      }${page ? `&page=${page}` : ""}${take ? `&take=${take}` : ""}`
    ),
  getAuditAssignmentById: (
    assignmentId: number
  ): Promise<AuditAssignmentEntityDto> =>
    doFetch(`/api/v3/audit/assign/user/${assignmentId}`, "GET"),
  getContractedAuditAssignments: (): Promise<AuditAssignmentEntityDto[]> =>
    doFetch(
      `/api/v3/audit/assign/company?contractedToCurrentOrganization=true`,
      "GET"
    ),

  getAuditResponseStat: (auditId: number): Promise<AuditStatsDto> =>
    doFetch(`/api/v3/audit/response/stats/${auditId}`, "GET"),

  getIndividualAudit: (incidentId: number) =>
    doFetch(`/api/v3/audit/response/id/${incidentId}`, "GET"),

  removeAuditFromSite: (locationId: number) =>
    doFetch(`/api/v3/audit/assign/location/${locationId}`, "DELETE"),

  deactivateUserEntity: (entityId: number) =>
    doFetch(`/api/v3/audit/assign/user/${entityId}`, "DELETE"),

  getStaffFilteredList: (
    jobId: number,
    { startDate, endDate }: { startDate?: string; endDate?: string }
  ): Promise<JobStaffDto[]> =>
    doFetch(
      `/api/v3/job/${jobId}/staff${startDate ? `?startDate=${startDate}` : ""}${
        endDate ? `&endDate=${endDate}` : ""
      }`,
      "GET"
    ),
};
