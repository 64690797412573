import React, { useState } from "react";
import { Form } from "react-bootstrap";

import { getAutocompleteType } from "../contact/ContactForm";

import { emailRegex } from "../../pages/auth/SignUp";

import BSModal, { BSModalDefaultProps } from "./BSModal";

declare type SendInvitationProps = BSModalDefaultProps;

const SendInvitation = ({ isVisible, onClose }: SendInvitationProps) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const validEmailAddress = emailRegex.test(email);
  return (
    <BSModal
      size="lg"
      modalBodyClassName="px-3 py-4"
      modalProps={{
        centered: true,
      }}
      hideHeader
      isVisible={isVisible}
      onClose={onClose}
    >
      <h2 className="h2-text px-2 px-sm-5 mb-3 text-center font-color-black border-bottom pb-3 work-sans">
        Invite a friend to insite.work
      </h2>
      <Form>
        <Form.Group className="mb-3" controlId="identity">
          <Form.Control
            type="text"
            required
            autoComplete={getAutocompleteType("email")}
            placeholder="Email"
            value={email}
            className="poppins"
            onChange={(e) => setEmail(e.target.value)}
            isInvalid={error}
          />
          {error && (
            <Form.Control.Feedback
              className="font-color-red poppins"
              type="invalid"
            >
              {error}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <div className="d-flex g-0 justify-content-between mt-3 row">
          <div className="col-12 col-md-5">
            <button
              title="close modal"
              type="button"
              aria-label="Close Modal"
              className="w-100 align-items-center btn primary-btn primary-btn-black col-12 col-sm-auto nav-btn"
              onClick={onClose}
            >
              Back
            </button>
          </div>

          <div className="col-12 col-md-5 mt-2 mt-md-0">
            <button
              title="submit"
              aria-label="Send Invitation"
              type="submit"
              disabled={!validEmailAddress || isLoading}
              className="w-100 align-items-center btn primary-btn col-12 col-sm-auto nav-btn"
              onClick={() => {}}
            >
              Send
            </button>
          </div>
        </div>
      </Form>
    </BSModal>
  );
};

export default SendInvitation;
